import React, { useEffect } from "react";
import "../../assets/css/style.css";
import CustomizedMenus from "./CustomizedMenus";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Badge } from "@mui/material";
import { getReporteesList } from "../../redux/slices/reportee";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Diversity3Icon from "@mui/icons-material/Diversity3";

function Header() {
  const dispatch = useDispatch();

  const { totalActiveEmp } = useSelector((state) => state.reportee);

  useEffect(() => {
    dispatch(getReporteesList());
  }, []);
  return (
    <AppBar style={{ top: "0px" }} elevation={0} position="sticky">
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div>
          <Badge color="secondary" badgeContent={totalActiveEmp}>
            <Diversity3Icon style={{ color: "#240230", fontSize: "30px" }} />
          </Badge>
        </div>

        <div>
          <CustomizedMenus />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
