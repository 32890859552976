import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  selfRating: [],
  mngrRating: [],
  totalRating: 5,
  loading: true,
};

const annualRatingSlice = createSlice({
  name: "annualokrrating",
  initialState,
  reducers: {
    getSelfRating(state, action) {
      state.selfRating = action.payload;
    },
    getMngrRating(state, action) {
      state.mngrRating = action.payload;
    },
  },
});

export default annualRatingSlice.reducer;

export function getSelfRating(user, myr) {
  return async (dispatch) => {
    let allRecords = await axios.post(config.API_URL + "/formrecords/all", {
      user: user,
      my: myr,
    });

    let res = await axios.post(
      config.API_URL + "/formrecords/annualokrrating",
      {
        user: Array.from(new Set(allRecords.data.result.map((rr)=>rr.user)))[0],
        my: myr,
      }
    );

    // console.log(res); // Add this line to verify that the API response data is correct

    dispatch(annualRatingSlice.actions.getSelfRating(res.data.result.appraisalRatingData));
  };
}
