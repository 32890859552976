import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  pfFeedback: [],
};

const pfFeedbackSlice = createSlice({
  name: "pfmonthfeedback",
  initialState,
  reducers: {
    getPFFeedback(state, action) {
      state.pfFeedback = action.payload;
    },
  },
});

export default pfFeedbackSlice.reducer;

export function getPFFeedback(user, my) {
  return async (dispatch) => {
    let res = await axios.post(config.API_URL + "/formrecords/sort", {
      user: user,
      my: my,
    });
    try {
      const pfQuestions = await axios.post(config.API_URL + "/questions/filter", {
        qlist: res.data.result[0]?.pf,
        formrecord: res.data.result[0]?._id,
      });

      dispatch(pfFeedbackSlice.actions.getPFFeedback(pfQuestions.data.result))
    } catch (error) {
        console.log(error)
    }
  };
}
