import React, { useEffect, useState } from "react";
import * as axios from "axios";
import { config } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { callData, callLoggedIn } from "../../redux/slices/userauth";
import Logo from "../../assets/ppm_logo_icon.png";
import { FormControl, TextField, Button, Alert } from "@mui/material";

let checkUserSession = async () => {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    let userSession = await axios.get(config.API_URL + "/users/verify", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${window.localStorage.getItem("accessToken")}`,
      },
    });

    return userSession;
  } else {
    return {};
  }
};

function CredentialLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const { loggedIn } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();

  useEffect(() => {
    function checkUser() {
      if (loggedIn === 0) {
        checkUserSession().then(function (data) {
          if (data.data.user.length) {
            dispatch(callData(data.data.user[0]));
            dispatch(callLoggedIn(1));
          } else {
            dispatch(callLoggedIn(0));
            console.log("Not Authorized");
          }
        });
      }
    }
    checkUser();
  }, [dispatch, loggedIn]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(false);
    const res = await axios.post(
      config.API_URL + "/users/login",
      { email, password },
      {
        "Content-Type": "application/json",
      }
    );
    const data = res.data;
    if (data.status === true) {
      window.localStorage.removeItem("accessToken");
      window.localStorage.setItem("accessToken", data.accessToken);
      axios.defaults.headers.common["Authorization"] = data.accessToken;
      dispatch(callData(data.result[0]));
      dispatch(callLoggedIn(1));
    } else {
      setError(true);
      dispatch(callLoggedIn(0));
      dispatch(callData(null));
    }
  };

  return (
    <main className="login-main login-bg" style={{ opacity: 1 }}>
      <div>
        <div className="login-content">
          <img
            src={Logo}
            title="PixelPay Media"
            alt="PixelPay Medi logo"
            width={200}
          />
          <div className="fs24 bold">
            PIXELPAY MEDIA
            <br /> PERFORMANCE PORTAL
          </div>
          <p className="fs20 m0">
            For transparency within all aspects of your growth, performance, and
            presence at PixelPay Media.{" "}
          </p>
          <form style={{ background: "white", padding: "2rem" }}>
            <FormControl margin="dense" fullWidth={true}>
              <TextField
                required
                id="outlined-required"
                onChange={(e) => setEmail(e.target.value)}
                label="Email Id"
                defaultValue={email}
              />
            </FormControl>
            <FormControl margin="dense" fullWidth={true}>
              <TextField
                required
                id="outlined-required"
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                type="password"
                defaultValue={password}
              />
            </FormControl>
            <div style={{ padding: "1rem 0" }}>
              <Button
                onClick={handleLogin}
                variant="outlined"
                size="small"
                type="submit"
                fullWidth
                color="primary"
              >
                LOGIN
              </Button>
            </div>
            {error && <Alert severity="error">Invalid login details.</Alert>}
          </form>
        </div>
      </div>
    </main>
  );
}

export default CredentialLogin;
