import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loggedIn: 0,
  userdata: {},
};

const slice = createSlice({
  name: "userauth",
  initialState,
  reducers: {
    getData(state, action) {
      state.userdata = action.payload;
    },
    getLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
  },
});

export default slice.reducer;

export function callLoggedIn(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getLoggedIn(status));
  };
}

export function callData(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getData(status));
  };
}
