import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { config } from "../../config"

const initialState = {
    crpRating: [],
    totalRating: 5,
    loading: true,
}

const continuousReviewSlice = createSlice({
    name:"continuousreview",
    initialState, 
    reducers: {
        getCRPRating(state, action) {
            state.crpRating = action.payload
        }
    }
})

export default continuousReviewSlice.reducer

export function getCRPRating(user, myr) {
    return async (dispatch) => {

        let allRecords = await axios.post(config.API_URL + "/formrecords/all", {
            user: user,
            my: myr,
          });


    let res = await axios.post(
        config.API_URL + "/formrecords/continuousreview",
        {
          user: Array.from(new Set(allRecords.data.result.map((rr)=>rr.user)))[0],
          my: myr,
        }
      );

    dispatch(continuousReviewSlice.actions.getCRPRating(res.data.result.crpQuarterData));
    }
}

