import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import OuterComponent from "./view/OuterPage/Outerpage";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ErrorBoundary from "./view/component/errorboundry";
import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("accessToken") || null;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/";
    }
    // reject with error if response status is not 403
    return Promise.reject(error);
  }
);

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <OuterComponent />
      </ReduxProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
