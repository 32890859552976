import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../config';

const initialState = {
  question: [],
  rquestion: [],
  pfquestion: [],
  formrecord: '',
  forms: {
    user: '',
    my: '',
    monthlyform: false,
    monthlyformemp: false,
    monthlyformmgr: false,
    pipform: false,
    pipformemp: false,
    pipformmgr: false,
    feedback: false,
    feedbackemp: false,
    feedbackmgr: false,
    ql: [],
    rql: [],
    pf: [],
  },
  fopen: false,
  edit: true,
  loading: true,
  activeReportee: '',
};

const slice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    getValue(state, action) {
      state.counter = action.payload;
    },
    getEdit(state, action) {
      state.edit = action.payload;
    },
    getActive(state, action) {
      state.activeReportee = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getFopen(state, action) {
      state.fopen = action.payload;
    },
    getFormRecord(state, action) {
      state.formrecord = action.payload;
    },
    getForms(state, action) {
      state.forms = action.payload;
    },
    getQuestion(state, action) {
      state.question = action.payload;
    },
    getRQuestion(state, action) {
      state.rquestion = action.payload;
    },
    getPFQuestion(state, action) {
      state.pfquestion = action.payload;
    },
  },
});

export default slice.reducer;

export function statusType(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getValue(status));
  };
}

export function EditC(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getEdit(status));
  };
}

export function ActiveReportee(val) {
  return async (dispatch) => {
    dispatch(slice.actions.getActive(val));
  };
}

export function updateRQuestion(val) {
  return async (dispatch) => {
    dispatch(slice.actions.getRQuestion(val));
  };
}

export function getQuestionData(user, my, formrecord) {
  return async (dispatch) => {
    dispatch(slice.actions.getLoading(true));
    dispatch(slice.actions.getFopen(false));
    const record = await axios.post(config.API_URL + '/formrecords/sort', {
      user: user,
      my: my,
    });
    dispatch(slice.actions.getForms(record.data.result[0]));
    if (
      record.data.result.length === 0 ||
      record.data.result[0] === undefined
    ) {

      dispatch(slice.actions.getFopen(false));
      dispatch(slice.actions.getLoading(false));
    } else {
   
      dispatch(slice.actions.getFormRecord(record.data.result[0]._id));
      const questions = await Promise.all(
        record.data.result.map(async (res) => {
          const ques = await axios.post(config.API_URL + '/questions/filter', {
            qlist: res.ql,
            formrecord: record.data.result[0]._id,
          });
          const rques = await axios.post(config.API_URL + '/questions/filter', {
            qlist: res.rql,
            formrecord: record.data.result[0]._id,
          });
          const pfques = await axios.post(
            config.API_URL + '/questions/filter',
            {
              qlist: res.pf,
              formrecord: record.data.result[0]._id,
            }
          );

          return [ques.data, rques.data, pfques.data];
        })
      );
      dispatch(slice.actions.getQuestion(questions[0][0]?.result));
      dispatch(slice.actions.getRQuestion(questions[0][1]?.result));
      dispatch(slice.actions.getPFQuestion(questions[0][2]?.result));
      dispatch(slice.actions.getFopen(true));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
