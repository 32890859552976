import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tomy: '01/22',
  loading: true,
  tovalue: new Date(),
};

const slice = createSlice({
  name: 'tomonthyear',
  initialState,
  reducers: {
    getMy(state, action) {
      state.tomy = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getValue(state, action) {
      state.tovalue = action.payload;
    },
  },
});

export default slice.reducer;

export function getMonthYear(tomonthyear) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getValue(tomonthyear));
      let dateArr = tomonthyear.toISOString().split('-');
      dispatch(slice.actions.getMy(dateArr[1] + '/' + (dateArr[0] % 2000)));
      dispatch(slice.actions.getLoading(false));
    } catch (error) {
      dispatch(slice.actions.getMy('01/22'));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
