import { createSlice } from "@reduxjs/toolkit";
import { defaultDate, defaultValue } from "../../view/component/helper";
import { format } from "date-fns";
const initialState = {
  my: defaultDate(),
  loading: true,
  value: defaultValue(),
};

const slice = createSlice({
  name: "monthyear",
  initialState,
  reducers: {
    getMy(state, action) {
      state.my = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getValue(state, action) {
      state.value = action.payload;
    },
  },
});

export default slice.reducer;

export function getMonthYear(monthyear) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getValue(monthyear));
      dispatch(slice.actions.getMy(format(monthyear, "MM/yy")));
      dispatch(slice.actions.getLoading(false));
    } catch (error) {
      dispatch(slice.actions.getMy(defaultDate));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
