import { format, addMonths } from "date-fns";
import dayjs from "dayjs";

export const unDefinedToObject = (val) => {
  if (typeof val === "undefined") {
    return {};
  } else {
    return val;
  }
};

export const unDefinedToString = (val) => {
  if (typeof val === "undefined") {
    return "";
  } else {
    return val;
  }
};

export const unDefinedToArray = (val) => {
  if (typeof val === "undefined") {
    return [];
  } else {
    return val;
  }
};

export const oneToTwo = (val) => {
  if (val < 10) {
    return "0" + val;
  } else return val;
};

export const getMY = (month, year) => {
  let m = oneToTwo(month);
  let y = year % 2000;
  return m + "/" + y;
};

export const isArrayHaveElement = (val) => {
  return val.length > 0;
};

export const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const rewardArray = [
  "Above and Beyond",
  "Ace of Initiatives",
  "Cruising and Crushing",
  "Out of the box thinking",
  "Inspiring Mentor",
  "Team - Gut and Grit",
  "Perfect Presence",
  "Rockstar Engager",
  "Bright Beginner",
  "One Year Tenure",
  "Three Year Tenure",
  "Five Year Tenure",
];
let date = new Date();
export const currentDate = () => {
  return date.getDay();
};
export const currentMonth = () => {
  return date.getMonth();
};
export const currentYear = () => {
  return date.getFullYear();
};

export const unDefinedAnswer = (val) => {
  if (typeof val === "undefined" || val === "") {
    return "* No Data Found";
  } else {
    return val;
  }
};

export const stringToInterger = (val) => {
  return parseInt(val);
};

export const MakeMonthRange = (value, tovalue) => {
  let dateArr = value.toISOString().split("-");
  let todateArr = tovalue.toISOString().split("-");

  let a = parseInt(dateArr[1]);
  let b = parseInt(todateArr[1]);

  let year = parseInt(dateArr[0]);
  let eyear = parseInt(todateArr[0]);

  let arr = [];

  if (eyear - year === 0) {
    while (a <= b) {
      let m = oneToTwo(a);
      let y = year % 2000;
      arr.push(m + "/" + y);
      a++;
    }
    return arr;
  } else {
    while (a <= 12 && eyear - year > 0) {
      let m = oneToTwo(a);
      let y = year % 2000;
      arr.push(m + "/" + y);
      a++;
      if (a === 13) {
        year = year + 1;
        a = 1;

        while (a <= b) {
          let m = oneToTwo(a);
          let y = year % 2000;
          arr.push(m + "/" + y);
          a++;
        }
      }
    }

    return arr;
  }
};

export const ThisMonthYearRange = () => {
  let a = 1;
  // let b = new Date().getMonth()
  let year = new Date().getFullYear();
  let eyear = new Date().getFullYear();
  let b = 12
  // let year = 2023
  // let eyear = 2023
  
  let arr = [];

  if (eyear - year === 0) {
    while (a <= b) {
      let m = oneToTwo(a);
      let y = year % 2000;
      arr.push(m + "/" + y);
      a++;
    }
    return arr;
  } else {
    while (a <= 12 && eyear - year > 0) {
      let m = oneToTwo(a);
      let y = year % 2000;
      arr.push(m + "/" + y);
      a++;
      if (a === 13) {
        year = year + 1;
        a = 1;

        while (a <= b) {
          let m = oneToTwo(a);
          let y = year % 2000;
          arr.push(m + "/" + y);
          a++;
        }
      }
    }
   
    return arr;
  }
};

export const defaultDate = () => {
  const today = format(addMonths(new Date(), -1), "MM/yy");
  return today;
};

export const defaultValue = () => {
  let val = format(addMonths(new Date(), -1), "yyyy-MM-dd");
  return dayjs(val);
};


export function getQuarter(qtr){
  const [month, year] = qtr.split('/');
  const monthIndex = parseInt(month, 10) - 1;
  const quarterIndex = Math.floor(monthIndex / 3);
  return `Q${quarterIndex + 1}`
}


export const getAppraisalDates= (dateStr) => {
  const [month, year] = dateStr.split('/');
  const date = new Date(`20${year}-${month}-01`); // add a day component to the date string
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' });
  return formatter.format(date);
}
