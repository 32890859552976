import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  question: [],
  formrecord: "",
  forms: {
    user: "",
    my: "",
    monthlyform: false,
    monthlyformemp: false,
    monthlyformmgr: false,
    pipform: false,
    pipformemp: false,
    pipformmgr: false,
    feedback: false,
    feedbackemp: false,
    feedbackmgr: false,
    ql: [],
    rql: [],
    pf: [],
    fq: [],
    pipq: [],
    apr: [],
    okr: [],
    kr: [],
  },

  edit: true,
  loading: true,
  activeReportee: "",
};

const slice = createSlice({
  name: "performance",
  initialState,
  reducers: {
    getValue(state, action) {
      state.counter = action.payload;
    },
    getEdit(state, action) {
      state.edit = action.payload;
    },
    getActive(state, action) {
      state.activeReportee = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getFopen(state, action) {
      state.fopen = action.payload;
    },
    getFormRecord(state, action) {
      state.formrecord = action.payload;
    },
    getForms(state, action) {
      state.forms = action.payload;
    },
    getQuestion(state, action) {
      state.question = action.payload;
    },
  },
});

export default slice.reducer;

export function updateQuestion(val) {
  return async (dispatch) => {
    dispatch(slice.actions.getQuestion(val));
  };
}

export function statusType(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getValue(status));
  };
}

export function EditC(status) {
  return async (dispatch) => {
    dispatch(slice.actions.getEdit(status));
  };
}

export function updateLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.getLoading(false));
  };
}

export function ActiveReportee(val) {
  return async (dispatch) => {
    dispatch(slice.actions.getActive(val));
  };
}

export function getQuestionData(user, my) {
  return async (dispatch) => {
    dispatch(slice.actions.getLoading(true));
    dispatch(slice.actions.getQuestion([]));
    const record = await axios.post(config.API_URL + "/formrecords/sort", {
      user: user,
      my: my,
    });
    dispatch(slice.actions.getForms(record.data.result[0]));
  };
}
