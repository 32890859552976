import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  score: 0,
  totalScore: 0,
  pfquestion: [],
  loading: true,
};

const slice = createSlice({
  name: "scorerecord",
  initialState,
  reducers: {
    getValue(state, action) {
      state.score = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getTotalScore(state, action) {
      state.totalScore = action.payload;
    },
    getPFQuestion(state, action) {
      state.pfquestion = action.payload;
    },
  },
});

export default slice.reducer;

export function getScore(user, my) {
  return async (dispatch) => {

    let res = await axios.post(config.API_URL + "/formrecords/sort", {
      user: user,
      my: my,
    });
    try {
      const ques = await axios.post(config.API_URL + "/questions/filter", {
        qlist: res.data.result[0]?.rql,
        formrecord: res.data.result[0]?._id,
      });
      const qa = await axios.post(config.API_URL + "/questions/filter", {
        qlist: res.data.result[0]?.pf,
        formrecord: res.data.result[0]?._id,
      });
      var totalscore = ques.data.result.length * 5;
      var total = 0;
      ques.data.result.forEach((element) => {
        total += parseInt(element.answers.answer);
      });
      dispatch(slice.actions.getPFQuestion(qa.data.result));
      dispatch(slice.actions.getValue(total));
      dispatch(slice.actions.getTotalScore(totalscore));
      dispatch(slice.actions.getLoading(false));
    } catch (error) {
      dispatch(slice.actions.getPFQuestion([]));
      dispatch(slice.actions.getValue(0));
      dispatch(slice.actions.getTotalScore(0));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
