import React from "react";
import Header from "../header/Header";
import { Switch, BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import CircularLoading from "../component/circularloading";
import CredentialLogin from "../login/CredentialLogin";

const CheckUser = () => {
  const { loggedIn, userdata } = useSelector((state) => state.userauth);
  const Sidebar = React.lazy(() => import("../sidebar/Sidebar"));
  const Routes = React.lazy(() => import("../Routes"));
  const ManagerRoutes = React.lazy(() => import("../ManagerRoute"));
  const AdminRoutes = React.lazy(() => import("../AdminRoute"));
  if (loggedIn === 0) {
    return <CredentialLogin />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <div className="dashboard">
            <Suspense fallback={<CircularLoading />}>
              <Sidebar props />
              <div className="main">
                <Header />
                <main className="p32">
                  {userdata.Role === "Admin" ? (
                    <AdminRoutes />
                  ) : userdata.Role === "Manager" ? (
                    <ManagerRoutes />
                  ) : (
                    <Routes />
                  )}
                </main>
              </div>
            </Suspense>
          </div>
        </Switch>
      </BrowserRouter>
    );
  }
};

function OuterComponent() {
  return <CheckUser />;
}

export default OuterComponent;
