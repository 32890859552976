import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../config';

const initialState = {
  scores: [],
  scores1: [],
  score: 0,
  score1: 0,
  totalScore: 0,
  totalScore1: 0,
  pfquestion: [],
  loading: true,
};

const slice = createSlice({
  name: 'scorerecordrange',
  initialState,
  reducers: {
    getScores(state, action) {
      state.scores = action.payload;
    },
    getScores1(state, action) {
      state.scores1 = action.payload;
    },
    getValue(state, action) {
      state.score = action.payload;
    },
    getValue1(state, action) {
      state.score1 = action.payload;
    },
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getTotalScore(state, action) {
      state.totalScore = action.payload;
    },
    getTotalScore1(state, action) {
      state.totalScore1 = action.payload;
    },
    getPFQuestion(state, action) {
      state.pfquestion = action.payload;
    },
  },
});

export default slice.reducer;

export function getScore(user, myr) {
  return async (dispatch) => {

    let res = await axios.post(config.API_URL + '/formrecords/all', {
      user: user,
      my: myr,
    });

    try {
      const quesans = await axios.post(
        config.API_URL + '/questions/filtermultiple',
        {

          qlist: [...new Set(res.data.result.map((ele) => ele.rql).flat(1))],
          formrecord: res.data.result.map((ele) => ele._id),
        }
      );

      function mergeArray(arr1, arr2) {
        return arr2.map((ele) => {
          return arr1.map((obj) => {
            if (obj._id === ele.formrecord) {
              return (ele.my = obj.my);
            }
          });
        });
      }
      let arr1 = res.data.result;
      let arr2 = quesans.data.result;

      mergeArray(arr1, arr2);
      let sum = 0;
      let scores = [];

      let length = 0;
      let sum1 = 0;
      let scores1 = [];
      let length1 = 0;

      myr.map((obj) => {
        arr2.map((ele) => {
          if (ele.my === obj && ele.type !== 7) {
            return (sum += parseInt(ele.answer)), (length += 1);
          } else if (ele.my === obj && ele.type === 7) {
            return (sum1 += parseInt(ele.answer)), (length1 += 1);
          }
        });
        scores.push({ my: obj, sum: sum, totalscore: length * 5 });
        scores1.push({ my: obj, sum1: sum1, totalscore1: length1 * 5 });
        sum = 0;
        length = 0;
        sum1 = 0;
        length1 = 0;
      });
      dispatch(slice.actions.getScores(scores));
      dispatch(slice.actions.getScores1(scores1));

      dispatch(slice.actions.getLoading(false));
    } catch (error) {

      dispatch(slice.actions.getScores([]));
      dispatch(slice.actions.getScores1([]));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
