import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  okrs: [],
  weightage: 0,
  loading: true,
};

const quaterlyOkrSlice = createSlice({
  name: "quaterlyokrs",
  initialState,
  reducers: {
    getQuaterlyOKRs(state, action) {
      state.okrs = action.payload;
    },
  },
});

export default quaterlyOkrSlice.reducer;

export function getQuaterlyOKRs(user, myr) {
  return async (dispatch) => {
    // Get Current Quarter 
    const [month, year] = myr.split("/");
    const date = new Date(`20${year}-${month}-01`);
    const quarter = Math.floor(date.getMonth() / 3) + 1;

    try {
        let allOKRs = await axios.post(
          config.API_URL + "/formrecords/quaterlyokrs",
          {
            user: user,
            quarter: quarter,
          }
        );
        dispatch(quaterlyOkrSlice.actions.getQuaterlyOKRs(allOKRs.data.result));
      } catch (error) {
        console.log(error);
      }
  };
}
