import React from "react";
import "./menu.css";
import Button from "@material-ui/core/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, withRouter } from "react-router-dom";
import { config } from "../../config";
import * as axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { callLoggedIn } from "../../redux/slices/userauth";

import { useHistory } from "react-router-dom";

function CustomizedMenus(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const { userdata } = useSelector((state) => state.userauth);

  const logout = async (params) => {
    window.localStorage.clear();
    await axios
      .delete(config.API_URL + "/users/auth/logout")
      .then(() => dispatch(callLoggedIn(0)));
    history.push("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Button
          id="basic-button"
          style={{
            backgroundImage: "url(" + userdata["Photo"] + ")",
            height: "45px",
            minWidth: "45px",
            backgroundSize: "cover",
            borderRadius: "50%",
            border: "1px solid #240130",
          }}
          variant="outlined"
          size="small"
          onClick={handleClick}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        ></Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <span onClick={logout}>
              <ListItemText color="black" primary="Log Out" />
            </span>
            {/* <GoogleLogout
              clientId="1037454523551-lp69q0glet6qcgku36j02qoqmtk6ucfj.apps.googleusercontent.com"
              render={(renderProps) => (
                <span
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <ListItemText color="black" primary="Log Out" />
                </span>
              )}
              buttonText="Logout"
              onLogoutSuccess={logout}
            ></GoogleLogout> */}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
export default withRouter(CustomizedMenus);
